import request from '@/auth/jwt/request'

/**
 * @description 新闻分类 -- 新闻分类列表
 * @param {Object} param params {Object} 传值参数
 */
export const newsCateListApi = params => { return request('website-news-category/list', 'get', params) }

/**
 * @description 新闻分类 -- 增加修改新闻分类
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const newsCateSaveApi = data => { return request('website-news-category/save', 'post', {}, data) }

/**
 * @description 新闻分类 -- 新闻分类状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const newsCateStatusApi = params => { return request('website-news-category/status', 'get', params) }

/**
 * @description 新闻分类 -- 新闻分类详情
 * @param {Object} param params {Object} 传值参数
 */
export const newsCateInfoApi = params => { return request('website-news-category/info', 'get', params) }
/**
 * @description 新闻分类 -- 删除新闻分类
 * { id = x }
 */
export const newsCateDeleteApi = data => { return request('website-news-category/delete', 'delete', data) }